<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" elevation="0" class="ma-1">
        <v-icon small left>$camera</v-icon>Camara</v-btn
      >
    </template>
    <v-card style="overflow-x: hidden">
      <v-card-title> {{$t("take_photo")}}</v-card-title>
      <camera :photos="photos"></camera>
      <form
        action
        data-vv-scope="design-form"
        @submit.prevent="validateForm('design-form')"
      >
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text @click="dialog = false" style="text-transform: uppercase">{{$t("cancel")}}</v-btn>
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "takephoto",
  components: {
    Camera: () => import("@/components/ui/Camera"),
  },
  props: ["route"],
  data() {
    return { dialog: false, photos: [] };
  },
  methods: {
    ...mapActions("files", ["addFiles"]),
    validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        if (result & (this.photos.length > 0)) {
          let formData = new FormData();
          if (this.route !== null) formData.append("route", this.route);

          this.photos.forEach((file) => {
            formData.append("files[]", file.src);
          });

          this.addFiles(formData).then((data) => {
            this.dialog = false;
            this.$emit("update", data);
            this.files = [];
          });
        }
      });
    },
  },
};
</script>
  